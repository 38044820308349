import { colors } from "utils/colors";

export const EnglishIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 4H6C4.89543 4 4 4.89543 4 6V26C4 27.1046 4.89543 28 6 28H26C27.1046 28 28 27.1046 28 26V6C28 4.89543 27.1046 4 26 4Z"
      stroke={colors.dark}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0001 11.3334H8.66675V20.6667H13.6667M8.66675 16H13.6667M17.3334 12.6667V20.6667V16.3334C17.3334 15.5377 17.6495 14.7747 18.2121 14.2121C18.7747 13.6494 19.5378 13.3334 20.3334 13.3334C21.1291 13.3334 21.8921 13.6494 22.4547 14.2121C23.0173 14.7747 23.3334 15.5377 23.3334 16.3334V20.6667"
      stroke={colors.dark}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
