import { colors } from "utils/colors";

export const AlienIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6667 24H17.3333M9.77733 13.3333H8.66667C8.48986 13.3333 8.32029 13.4036 8.19526 13.5286C8.07024 13.6536 8 13.8232 8 14V15.1106C8 16.0538 8.37465 16.9582 9.04153 17.6251C9.70841 18.292 10.6129 18.6666 11.556 18.6666H12.6667C12.8435 18.6666 13.013 18.5964 13.1381 18.4714C13.2631 18.3464 13.3333 18.1768 13.3333 18V16.8893C13.3333 15.9462 12.9587 15.0417 12.2918 14.3748C11.6249 13.708 10.7204 13.3333 9.77733 13.3333ZM22.2227 13.3333H22.6667C23.2947 13.3333 23.6093 13.3333 23.8053 13.528C24 13.724 24 14.0386 24 14.6666V15.1106C24 16.0538 23.6254 16.9582 22.9585 17.6251C22.2916 18.292 21.3871 18.6666 20.444 18.6666H20C19.372 18.6666 19.0573 18.6666 18.8613 18.472C18.6667 18.276 18.6667 17.96 18.6667 17.3333V16.8893C18.6667 16.4223 18.7586 15.9599 18.9374 15.5285C19.1161 15.0971 19.378 14.705 19.7082 14.3748C20.0384 14.0446 20.4304 13.7827 20.8618 13.604C21.2933 13.4253 21.7557 13.3333 22.2227 13.3333Z"
      stroke={colors.dark}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28 14.6667C28 22.0307 20 29.3333 16 29.3333C12 29.3333 4 22.0307 4 14.6667C4 7.30266 9.37333 2.66666 16 2.66666C22.6267 2.66666 28 7.30266 28 14.6667Z"
      stroke={colors.dark}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
