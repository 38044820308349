import { colors } from "utils/colors";

export const BicycleIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00008 26.664C10.9467 26.664 13.3334 24.2813 13.3334 21.3413C13.3327 20.6417 13.1942 19.949 12.9258 19.3028C12.6574 18.6567 12.2644 18.0697 11.7691 17.5754C11.2739 17.0812 10.6861 16.6893 10.0394 16.4222C9.39273 16.1551 8.69976 16.018 8.00008 16.0187C5.05341 16.0187 2.66675 18.4013 2.66675 21.3413C2.66745 22.041 2.80595 22.7337 3.07436 23.3799C3.34276 24.026 3.73581 24.613 4.23105 25.1072C4.7263 25.6015 5.31405 25.9934 5.96074 26.2605C6.60743 26.5276 7.3004 26.6647 8.00008 26.664ZM24.0001 26.664C26.9467 26.664 29.3334 24.2813 29.3334 21.3413C29.3327 20.6417 29.1942 19.949 28.9258 19.3028C28.6574 18.6567 28.2644 18.0697 27.7691 17.5754C27.2739 17.0812 26.6861 16.6893 26.0394 16.4222C25.3927 16.1551 24.6998 16.018 24.0001 16.0187C21.0534 16.0187 18.6667 18.4013 18.6667 21.3413C18.6674 22.041 18.806 22.7337 19.0744 23.3799C19.3428 24.026 19.7358 24.613 20.2311 25.1072C20.7263 25.6015 21.314 25.9934 21.9607 26.2605C22.6074 26.5276 23.3004 26.6647 24.0001 26.664Z"
      stroke={colors.dark}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00008 21.3413H13.8267C14.0623 21.3416 14.2938 21.2794 14.4976 21.1611C14.7013 21.0429 14.8701 20.8727 14.9867 20.668L20.6667 10.6947M16.0001 17.3493L9.33341 9.36399M9.33341 9.36399H6.66675M9.33341 9.36399H12.0001M24.0707 21.2933L19.7414 5.37332C21.6747 5.27999 25.8121 5.10665 26.6547 8.27999"
      stroke={colors.dark}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
