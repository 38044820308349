import { colors } from "utils/colors";

export const CalcIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.75733 27.3813C7.51467 29.3333 10.344 29.3333 16 29.3333C21.656 29.3333 24.4853 29.3333 26.2427 27.38C28 25.4293 28 22.2853 28 16C28 9.71467 28 6.572 26.2427 4.61867C24.4853 2.66534 21.656 2.66667 16 2.66667C10.344 2.66667 7.51467 2.66667 5.75733 4.61867C4 6.57334 4 9.71467 4 16C4 22.2853 4 25.4293 5.75733 27.3813Z"
      stroke={colors.dark}
    />
    <path
      d="M9.33325 10.6667C9.33325 10.0467 9.33325 9.73733 9.40125 9.48267C9.49218 9.14382 9.67063 8.83486 9.9187 8.58678C10.1668 8.33871 10.4757 8.16026 10.8146 8.06933C11.0719 8 11.3813 8 11.9999 8H19.9999C20.6199 8 20.9293 8 21.1839 8.068C21.5228 8.15892 21.8317 8.33738 22.0798 8.58545C22.3279 8.83353 22.5063 9.14249 22.5973 9.48133C22.6666 9.73867 22.6666 10.048 22.6666 10.6667C22.6666 11.2853 22.6666 11.596 22.5986 11.8507C22.5077 12.1895 22.3292 12.4985 22.0811 12.7465C21.8331 12.9946 21.5241 13.1731 21.1853 13.264C20.9293 13.3333 20.6186 13.3333 19.9999 13.3333H11.9999C11.3799 13.3333 11.0706 13.3333 10.8159 13.2653C10.4771 13.1744 10.1681 12.996 9.92004 12.7479C9.67196 12.4998 9.49351 12.1908 9.40259 11.852C9.33325 11.5947 9.33325 11.2853 9.33325 10.6667Z"
      stroke={colors.dark}
    />
    <path
      d="M10.6666 18.6667C11.403 18.6667 11.9999 18.0697 11.9999 17.3333C11.9999 16.597 11.403 16 10.6666 16C9.93021 16 9.33325 16.597 9.33325 17.3333C9.33325 18.0697 9.93021 18.6667 10.6666 18.6667Z"
      fill={colors.dark}
    />
    <path
      d="M10.6666 24C11.403 24 11.9999 23.4031 11.9999 22.6667C11.9999 21.9303 11.403 21.3333 10.6666 21.3333C9.93021 21.3333 9.33325 21.9303 9.33325 22.6667C9.33325 23.4031 9.93021 24 10.6666 24Z"
      fill={colors.dark}
    />
    <path
      d="M16.0001 18.6667C16.7365 18.6667 17.3334 18.0697 17.3334 17.3333C17.3334 16.597 16.7365 16 16.0001 16C15.2637 16 14.6667 16.597 14.6667 17.3333C14.6667 18.0697 15.2637 18.6667 16.0001 18.6667Z"
      fill={colors.dark}
    />
    <path
      d="M16.0001 24C16.7365 24 17.3334 23.4031 17.3334 22.6667C17.3334 21.9303 16.7365 21.3333 16.0001 21.3333C15.2637 21.3333 14.6667 21.9303 14.6667 22.6667C14.6667 23.4031 15.2637 24 16.0001 24Z"
      fill={colors.dark}
    />
    <path
      d="M21.3333 18.6667C22.0697 18.6667 22.6667 18.0697 22.6667 17.3333C22.6667 16.597 22.0697 16 21.3333 16C20.597 16 20 16.597 20 17.3333C20 18.0697 20.597 18.6667 21.3333 18.6667Z"
      fill={colors.dark}
    />
    <path
      d="M21.3333 24C22.0697 24 22.6667 23.4031 22.6667 22.6667C22.6667 21.9303 22.0697 21.3333 21.3333 21.3333C20.597 21.3333 20 21.9303 20 22.6667C20 23.4031 20.597 24 21.3333 24Z"
      fill={colors.dark}
    />
  </svg>
);
