import { colors } from "utils/colors";

export const PageIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.1026 26.6667H9.33325V5.33334H23.9999V24H17.2306"
      stroke={colors.dark}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
