import { colors } from "utils/colors";

export const ChatIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 28C18.3734 28 20.6935 27.2962 22.6668 25.9776C24.6402 24.6591 26.1783 22.7849 27.0866 20.5922C27.9948 18.3995 28.2324 15.9867 27.7694 13.6589C27.3064 11.3311 26.1635 9.19295 24.4853 7.51472C22.807 5.83649 20.6689 4.6936 18.3411 4.23058C16.0133 3.76756 13.6005 4.0052 11.4078 4.91345C9.21509 5.8217 7.34094 7.35977 6.02236 9.33316C4.70379 11.3066 4 13.6266 4 16C4 17.92 4.45067 19.7333 5.25333 21.3427C5.85733 22.5573 5.01733 24.196 4.69733 25.392C4.62644 25.6565 4.62643 25.935 4.6973 26.1996C4.76817 26.4641 4.90742 26.7053 5.10107 26.8989C5.29471 27.0926 5.53592 27.2318 5.80044 27.3027C6.06496 27.3736 6.34348 27.3736 6.608 27.3027C7.804 26.9827 9.44267 26.1427 10.6573 26.748C12.3176 27.5724 14.1463 28.0009 16 28Z"
      stroke={colors.dark}
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
