import { createStyles } from "@mantine/core";
import { colors } from "utils/colors";
import { maxWidth } from "utils/responsive";

const radius = 100;
const mobileRadius = 20;
const commonVerticalPadding = 55;
const commonHorizontalPadding = 20;

export const useMainPageStyles = createStyles((theme, _params, getRef) => ({
  firstScreen: {
    padding: `45px 12px 100px 12px`,
    [maxWidth.desktop]: {
      padding: `25px 12px 50px 12px`,
    },
  },
  firstScreenHeader: {
    width: 900,
    maxWidth: "100%",
    margin: "0 auto 40px auto",
  },
  firstScreenAvatar: {
    marginBottom: 24,
  },
  firstScreenHeadline: {
    marginBottom: 12,
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.4,
    width: "400px",
    maxWidth: "100%",
    [maxWidth.desktop]: {
      fontSize: 16,
    },
  },
  firstScreenHeadText: {
    fontSize: 20,
    fontWeight: 500,
  },

  storyText: {
    fontSize: 20,
    lineHeight: 1.4,
    [maxWidth.desktop]: {
      fontSize: 16,
    },
  },

  contactShell: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contactIcon: {
    width: 115,
    height: 115,
    background: colors.lightColor,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
  },
  contactIconCenter: {
    width: 40,
    "& > svg": {
      display: "block",
    },
  },
  contactHeadline: {
    fontSize: 38,
    fontWeight: 500,
    textAlign: "center",
    margin: `24px 0 20px 0`,
    [maxWidth.desktop]: {
      fontSize: 30,
    },
  },

  paddingSection: {
    padding: `${commonVerticalPadding}px ${commonHorizontalPadding}px`,
  },
  radiusSection: {
    borderRadius: `0 0 ${radius}px ${radius}px`,
    [maxWidth.desktop]: {
      borderRadius: `0 0 ${mobileRadius}px ${mobileRadius}px`,
    },
  },
  commonSection: {
    position: "relative",
    "&:before": {
      content: `""`,
      pointerEvents: "none",
      position: "absolute",
      bottom: "100%",
      left: 0,
      height: radius,
      width: "100%",
    },
  },
  sectionGrey: {
    backgroundColor: colors.greyColor,
    "&:before": {
      backgroundColor: colors.greyColor,
    },
  },
  sectionLight: {
    backgroundColor: colors.lightColor,
    "&:before": {
      backgroundColor: colors.lightColor,
    },
  },
  sectionHeader: {
    marginBottom: 35,
    [maxWidth.desktop]: {
      marginBottom: 15,
    },
  },
  sectionHeadline: {
    fontSize: 26,
    lineHeight: 1.3,
    fontWeight: 500,
    textAlign: "center",
    marginBottom: 12,
    [maxWidth.desktop]: {
      fontSize: 20,
    },
  },
  sectionDescription: {
    fontSize: 17,
    lineHeight: 1.4,
    textAlign: "center",
    [maxWidth.desktop]: {
      fontSize: 15,
    },
  },
  sectionDivider: {
    marginBottom: 55,
    [maxWidth.desktop]: {
      marginBottom: 25,
    },
  },
  sectionItem: {
    [maxWidth.desktop]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  sectionItemTitle: {
    margin: `9px 0 14px 0`,
    fontSize: 18,
    lineHeight: 1.4,
    [maxWidth.desktop]: {
      textAlign: "center",
    },
  },
  sectionItemDescription: {
    fontSize: 16,
    lineHeight: 1.4,
    [maxWidth.desktop]: {
      textAlign: "center",
    },
  },

  localButton: {
    background: colors.dark,
    color: "white",
    borderRadius: 1000,
    height: 54,
    padding: "0px 36px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 12,
    fontSize: 20,
    border: "none",
    cursor: "pointer",
    transition: "0.1s",
    textDecoration: "none",
    "&:hover": {
      background: "#000",
    },
  },

  demo: {
    borderRadius: 12,
    background: "#F8F8F8",
    padding: "34px 24px",
  },
  demoPicture: {
    display: "block",
    "& img": {
      borderRadius: 12,
      display: "block",
    },
  },

  demoInformation: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 12,
  },
  demoTitle: {
    fontSize: 18,
    lineHeight: "120%",
    marginBottom: 12,
  },
  demoDescription: {
    fontSize: 16,
    whiteSpace: "pre-wrap",
  },
  demoLink: {
    fontSize: 18,
    color: "inherit",
  },
}));
