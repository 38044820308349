import { colors } from "utils/colors";

export const BookIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 26.6667C16 25.6058 15.5786 24.5884 14.8284 23.8382C14.0783 23.0881 13.0609 22.6667 12 22.6667H6.66667C5.40933 22.6667 4.78133 22.6667 4.39067 22.276C4 21.8853 4 21.2573 4 20V8.00001C4 6.74268 4 6.11468 4.39067 5.72401C4.78133 5.33334 5.40933 5.33334 6.66667 5.33334H8C11.7707 5.33334 13.6573 5.33334 14.828 6.50534C16 7.67601 16 9.56268 16 13.3333M16 26.6667V13.3333M16 26.6667C16 25.6058 16.4214 24.5884 17.1716 23.8382C17.9217 23.0881 18.9391 22.6667 20 22.6667H25.3333C26.5907 22.6667 27.2187 22.6667 27.6093 22.276C28 21.8853 28 21.2573 28 20V8.00001C28 6.74268 28 6.11468 27.6093 5.72401C27.2187 5.33334 26.5907 5.33334 25.3333 5.33334H24C20.2293 5.33334 18.3427 5.33334 17.172 6.50534C16 7.67601 16 9.56268 16 13.3333"
      stroke={colors.dark}
    />
  </svg>
);
