import { colors } from "utils/colors";

export const NetworkIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4667 29.3333L27.2 29.3333C27.6418 29.3333 28 28.9752 28 28.5333L28 23.4667C28 23.0248 27.6418 22.6667 27.2 22.6667L19.4667 22.6667C19.0248 22.6667 18.6667 23.0248 18.6667 23.4667L18.6667 28.5333C18.6667 28.9752 19.0248 29.3333 19.4667 29.3333Z"
      stroke={colors.dark}
    />
    <path
      d="M12.1334 9.33333L19.8667 9.33333C20.3086 9.33333 20.6667 8.97516 20.6667 8.53333L20.6667 3.46666C20.6667 3.02483 20.3086 2.66666 19.8667 2.66666L12.1334 2.66666C11.6916 2.66666 11.3334 3.02483 11.3334 3.46666L11.3334 8.53333C11.3334 8.97515 11.6916 9.33333 12.1334 9.33333Z"
      stroke={colors.dark}
    />
    <path
      d="M4.79992 29.3333L12.5333 29.3333C12.9751 29.3333 13.3333 28.9752 13.3333 28.5333L13.3333 23.4667C13.3333 23.0248 12.9751 22.6667 12.5333 22.6667L4.79992 22.6667C4.35809 22.6667 3.99992 23.0248 3.99992 23.4667L3.99992 28.5333C3.99992 28.9752 4.35809 29.3333 4.79992 29.3333Z"
      stroke={colors.dark}
    />
    <path
      d="M23.3333 22.6667L23.3333 18C23.3333 17.2927 23.0523 16.6145 22.5522 16.1144C22.0521 15.6143 21.3738 15.3333 20.6666 15.3333L11.3333 15.3333C10.626 15.3333 9.94773 15.6143 9.44764 16.1144C8.94754 16.6145 8.66659 17.2927 8.66659 18L8.66659 22.6667M15.9999 15.3333L15.9999 9.33332"
      stroke={colors.dark}
    />
  </svg>
);
