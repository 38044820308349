import { colors } from "utils/colors";

export const HandShakeIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.56567 8.81421L7.83425 12.1856L13.4342 11.2199C14.3666 11.0586 15.3232 11.1069 16.2345 11.3615C17.1458 11.616 17.989 12.0703 18.7028 12.6914M32.2342 26.5199L23.3714 32.8171C22.4199 33.4931 21.2841 33.8615 20.117 33.8727C18.9498 33.8839 17.8072 33.5375 16.8428 32.8799L1.56567 22.4656"
      stroke={colors.dark}
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.2913 22.4L32.0199 26.6857L22.2885 18.8286L18.7085 21.4314C18.0078 21.9402 17.137 22.1572 16.2796 22.0367C15.4221 21.9163 14.6448 21.4678 14.1113 20.7857C13.5808 20.104 13.3347 19.2434 13.4247 18.3842C13.5147 17.5251 13.9338 16.7341 14.5942 16.1772L18.3228 13.0429C19.2066 12.2989 20.2316 11.7413 21.3363 11.4035C22.441 11.0657 23.6026 10.9547 24.7513 11.0772L30.5599 11.7L38.2913 7.52002"
      stroke={colors.dark}
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.2886 18.8486C24.6314 20.9343 27.9514 19.8686 29.1857 18.1658"
      stroke={colors.dark}
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
