import { colors } from "utils/colors";

export const TelegramIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9838 16.6921L16.496 20.6852C17.796 22.164 18.447 22.9039 19.1285 22.723C19.8088 22.5431 20.0428 21.5703 20.5097 19.6236L23.0989 8.8249C23.8193 5.82623 24.179 4.32798 23.3795 3.58806C22.58 2.84815 21.1944 3.39848 18.4232 4.49806L5.56838 9.60381C3.35188 10.4846 2.24363 10.9244 2.17321 11.6806C2.16492 11.7576 2.16492 11.8353 2.17321 11.9124C2.24146 12.6696 3.34754 13.1127 5.56188 14C6.56396 14.4019 7.06555 14.6034 7.42521 14.988C7.46566 15.0313 7.50466 15.0761 7.54221 15.1223C7.87371 15.534 8.01455 16.0756 8.2973 17.1546L8.82705 19.1772C9.10113 20.2281 9.23871 20.7546 9.59946 20.8261C9.96021 20.8976 10.2733 20.4621 10.9005 19.59L12.9838 16.6921ZM12.9838 16.6921L12.6404 16.3346C12.2482 15.9251 12.0521 15.7214 12.0521 15.4679C12.0521 15.2144 12.2471 15.0096 12.6404 14.6012L16.5111 10.5669"
      stroke="white"
      stroke-width="0.875"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
