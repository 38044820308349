export const ScrollIcon = () => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.987 7.40894V12.7389M13 23.8334C19.5 23.8334 21.125 18.9475 21.125 13C21.125 7.05252 19.5 2.16669 13 2.16669C6.5 2.16669 4.875 7.05252 4.875 13C4.875 18.9475 6.5 23.8334 13 23.8334Z"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8291 8.51391C11.8951 7.40891 12.5451 6.43391 13.0391 6.50324C13.4551 6.49999 13.8451 7.14891 15.1711 8.51391M15.1711 12.0683C14.1051 13.1733 13.4551 14.1483 12.9611 14.079C12.5451 14.0822 12.1551 13.4344 10.8291 12.0694"
      stroke="white"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
