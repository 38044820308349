import { AlienIcon } from "icons/AlienIcon";
import { BookIcon } from "icons/BookIcon";
import { CalcIcon } from "icons/CalcIcon";
import { ChatIcon } from "icons/ChatIcon";
import { NetworkIcon } from "icons/NetworkIcon";
import { PageIcon } from "icons/PageIcon";
import { ThreeDIcon } from "icons/ThreeDIcon";

type Service = {
  icon: React.ReactNode;
  title: string;
  description: string;
};

const draftServices: Service[] = [
  {
    icon: <ThreeDIcon />,
    title: "Это может быть 3д анимация для сайта",
    description:
      "Например для удержания клиента на сайте вы можете добавить 3д модель вашего товара, что может повысить количество заявок и выделить на фоне конкурентов",
  },
];

export const services: Service[] = [
  {
    icon: <PageIcon />,
    title: "Сделать сайт по дизайну",
    description:
      "У вас есть продукт или услуга, дизайнер нарисовал вам сайт и вам нужно чтобы люди могли найти рабочий нарисованный сайт в интернете",
  },
  {
    icon: <CalcIcon />,
    title: "Будь это какой то калькулятор для сайта",
    description:
      "Например где твои клиенты могут посчитать сколько им обойдется заказать пластиковое окно.",
  },
  {
    icon: <ChatIcon />,
    title: "Сделать телеграм бота с приложением",
    description:
      "Все больше людей пользуются телеграмом и компаниям выгодно быть ближе к своим клиентам. Заходя в телеграм каждый день, ваш клиент лишний раз может увидеть напоминание о услуге вашей компании или товаре.",
  },
  {
    icon: <NetworkIcon />,
    title: "Сделать социальную сеть с любой логикой",
    description:
      "В последнее время компании заказывают социальные сети для своих сотрудников чтобы автоматизировать и упростить работу между сотрудниками для увеличения прибыли.",
  },
  {
    icon: <BookIcon />,
    title: "Сделать форум по специальному техническому заданию",
    description:
      "Сделать форум или базу знаний по специальному техническому заданию.",
  },
  {
    icon: <AlienIcon />,
    title: "У вас есть уникальная идея?",
    description:
      "Я готов вникнуть в вашу сферу и решить вашу проблему через автоматизацию. Можно сделать все что угодно, только если это не приложению по поиску инопланетян).",
  },
];
