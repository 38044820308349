import { colors } from "utils/colors";

export const ThreeDIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.0666 3.83467L6.12657 8.24267C5.50691 8.5881 4.99103 9.09315 4.63253 9.70534C4.27403 10.3175 4.08597 11.0146 4.08791 11.724V20.276C4.08621 20.9852 4.27438 21.682 4.63287 22.2939C4.99136 22.9058 5.50711 23.4107 6.12657 23.756L14.0692 28.1653C14.6601 28.4941 15.3251 28.6667 16.0012 28.6667C16.6774 28.6667 17.3424 28.4941 17.9332 28.1653L25.8759 23.7573C26.4956 23.4119 27.0114 22.9069 27.3699 22.2947C27.7285 21.6825 27.9165 20.9854 27.9146 20.276V11.724C27.9163 11.0148 27.7281 10.318 27.3696 9.7061C27.0111 9.09416 26.4954 8.58932 25.8759 8.244L17.9332 3.83467C17.3424 3.50589 16.6774 3.33333 16.0012 3.33333C15.3251 3.33333 14.6601 3.50589 14.0692 3.83467H14.0666Z"
      stroke={colors.dark}
    />
    <path
      d="M27.1066 9.328L16 16M16 16L4.89331 9.328M16 16V28.6533"
      stroke={colors.dark}
    />
  </svg>
);
